var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list"},[_c('v-row',{staticClass:"ma-5"},[_c('import-excel',{attrs:{"dialog":_vm.dialog},on:{"close-dialog":function($event){_vm.refreshData();
        _vm.dialog = false;},"open":function($event){_vm.dialog = true}}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"390"},model:{value:(_vm.showDeleteConfirmation),callback:function ($$v) {_vm.showDeleteConfirmation=$$v},expression:"showDeleteConfirmation"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Hapus Data? ")]),_c('v-card-text',[_vm._v("Data akan dihapus secara permanen dan tidak akan bisa dikembalikan.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deletePasien}},[_vm._v(" Hapus ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDeleteConfirmation = false}}},[_vm._v(" batal ")])],1)],1)],1),_c('v-btn',{staticClass:"primary ml-2",attrs:{"to":{ name: 'Add' }}},[_vm._v("Buat Registrasi")]),_c('v-btn',{staticClass:"info ml-2",attrs:{"disabled":_vm.selectedPasien.length === 0},on:{"click":_vm.printSelected}},[_vm._v("Print yang dipilih")])],1),_c('v-row',[_c('v-card',{staticClass:"ma-5",attrs:{"width":"100%","elevation":"5"}},[_c('v-card-title',[_vm._v(" Daftar Registrasi Pasien "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"loading-text":"Loading data...","headers":_vm.headers,"items":_vm.pasienList,"search":_vm.search,"show-select":""},scopedSlots:_vm._u([{key:"item.birthDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localDate(item.birthDate))+" ")]}},{key:"item.samplingDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localDate(item.samplingDate))+" ")]}},{key:"item.createdDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localDate(item.createdDate))+" ")]}},{key:"item.updatedDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localDate(item.updatedDate))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.showPasien(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","to":{ name: 'Edit', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.confirmDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.selectedPasien),callback:function ($$v) {_vm.selectedPasien=$$v},expression:"selectedPasien"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }