<template>
  <div class="list">
    <v-row class="ma-5">
      <import-excel
        :dialog="dialog"
        @close-dialog="
          refreshData();
          dialog = false;
        "
        @open="dialog = true"
      />
      <v-dialog v-model="showDeleteConfirmation" persistent max-width="390">
        <v-card>
          <v-card-title class="text-h5"> Hapus Data? </v-card-title>
          <v-card-text
            >Data akan dihapus secara permanen dan tidak akan bisa
            dikembalikan.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="deletePasien"> Hapus </v-btn>
            <v-btn text @click="showDeleteConfirmation = false"> batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn class="primary ml-2" :to="{ name: 'Add' }">Buat Registrasi</v-btn>
      <v-btn
        class="info ml-2"
        @click="printSelected"
        :disabled="selectedPasien.length === 0"
        >Print yang dipilih</v-btn
      >
    </v-row>
    <v-row>
      <v-card width="100%" class="ma-5" elevation="5">
        <v-card-title>
          Daftar Registrasi Pasien
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Cari"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :loading="isLoading"
          loading-text="Loading data..."
          :headers="headers"
          :items="pasienList"
          :search="search"
          show-select
          v-model="selectedPasien"
        >
          <template v-slot:[`item.birthDate`]="{ item }">
            {{ localDate(item.birthDate) }}
          </template>
          <template v-slot:[`item.samplingDate`]="{ item }">
            {{ localDate(item.samplingDate) }}
          </template>
          <template v-slot:[`item.createdDate`]="{ item }">
            {{ localDate(item.createdDate) }}
          </template>
          <template v-slot:[`item.updatedDate`]="{ item }">
            {{ localDate(item.updatedDate) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon class="mr-1" @click="showPasien(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-1"
              :to="{ name: 'Edit', params: { id: item.id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon class="mr-1" @click="confirmDelete(item.id)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import ImportExcel from "./ImportExcel.vue";

export default {
  components: {
    ImportExcel,
  },
  data() {
    return {
      showDeleteConfirmation: false,
      dialog: false,
      isLoading: true,
      search: "",
      selectedPasien: [],
      selectedIdTobeDeleted: 0,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Birth Date", value: "birthDate" },
        { text: "Gender", value: "gender" },
        { text: "Request Lab", value: "requestLab" },
        { text: "Sampling Date", value: "samplingDate" },
        { text: "Location", value: "location" },
        { text: "Created At", value: "createdDate" },
        { text: "Created By", value: "userNameCreate" },
        { text: "Updated At", value: "updatedDate" },
        { text: "Updated By", value: "userNameUpdate" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    pasienList() {
      return this.$store.getters.pasienList;
    },
  },
  methods: {
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
    refreshData() {
      this.$store.dispatch("getPasienList");
    },
    confirmDelete(id) {
      this.selectedIdTobeDeleted = id;
      this.showDeleteConfirmation = true;
    },
    deletePasien() {
      this.$store
        .dispatch("deletePasien", this.selectedIdTobeDeleted)
        .then(() => {
          this.showDeleteConfirmation = false;
        });
    },
    showPasien(item) {
      this.$store.dispatch("getSelectedPasien", item);
      this.$router.push({ name: "Show" });
    },
    printSelected() {
      this.$store.dispatch("getPasienForPrint", this.selectedPasien);
      this.$router.push(`/print`);
    },
  },
  mounted() {
    this.$store
      .dispatch("getPasienList")
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.log("Error fetching Pasien List");
        console.log(error);
      });
  },
};
</script>
