<template>
  <div class="text-center">
    <v-btn color="red" dark @click.stop="$emit('open')">
      Impor dari Excel
    </v-btn>
    <v-dialog :value="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Impor data dari Excel
        </v-card-title>

        <v-card-text>
          <v-form class="ma-5">
            <span>File Excel untuk di upload</span>
            <v-file-input
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Pilih file excel untuk di upload"
              prepend-icon="mdi-file-excel"
              label="File Excel"
              @change="previewData"
            ></v-file-input>
          </v-form>
          <v-row justify="center">
            <h2 class="ma-5">{{ statusText }}</h2>
          </v-row>
          <v-progress-linear
            v-if="showProgressBar"
            :value="progressData"
            color="primary"
            height="25"
          >
            <strong class="white--text">{{ Math.ceil(progressData) }}%</strong>
          </v-progress-linear>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text href="/template.xlsx">
            Download Template
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="importData"
            :loading="isLoading"
            :disabled="dataToBeImported.length === 0"
          >
            Impor Data
          </v-btn>
          <v-btn
            color="danger"
            text
            @click="
              resetData();
              $emit('close-dialog');
            "
            >Tutup</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
  },
  data() {
    return {
      isLoading: false,
      dataToBeImported: [],
      showProgressBar: false,
      progressData: 0,
      totalSuccessData: 0,
      totalFailedData: 0,
      statusText: "Tidak ada data",
      doneImporting: false,
      currentUser: JSON.parse(localStorage.getItem("current_user")),
    };
  },
  methods: {
    resetData() {
      this.isLoading = false;
      this.dataToBeImported = [];
      this.showProgressBar = false;
      this.progressData = 0;
      this.statusText = "Tidak ada data";
      this.doneImporting = false;
    },
    previewData(file) {
      this.dataToBeImported = [];
      if (file != null) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: "binary",
            cellDates: true,
            cellNF: false,
          });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {
            header: [
              "name",
              "requestLab",
              "nationality",
              "identityNumber",
              "passportNumber",
              "birthDate",
              "gender",
              "phoneNumber",
              "email",
              "address",
              "samplingDate",
              "sampleSender",
              "receiveSampleDate",
              "resultDate",
              "antigenResult",
              "pcrResult",
              "ctValue",
            ],
            raw: false,
          });
          data.shift();
          this.dataToBeImported = data;
          this.statusText = `${data.length} data akan di impor`;
        };

        reader.readAsBinaryString(file);
      }
    },
    async importData() {
      this.isLoading = true;
      this.showProgressBar = true;
      let totalData = this.dataToBeImported.length;
      let processedData = 0;
      for (const data of this.dataToBeImported) {
        let birthDate = new Date(Date.parse(data.birthDate))
          .toLocaleString("sv", {
            timeZoneName: "short",
          })
          .substr(0, 10);
        let samplingDate = new Date(Date.parse(data.samplingDate))
          .toLocaleString("sv", {
            timeZoneName: "short",
          })
          .substr(0, 10);
        let receiveSampleDate = new Date(Date.parse(data.receiveSampleDate))
          .toLocaleString("sv", {
            timeZoneName: "short",
          })
          .substr(0, 10);
        let resultDate = new Date(Date.parse(data.resultDate))
          .toLocaleString("sv", {
            timeZoneName: "short",
          })
          .substr(0, 10);
        let {
          name,
          requestLab,
          nationality,
          identityNumber,
          passportNumber,
          gender,
          phoneNumber,
          email,
          address,
          sampleSender,
          antigenResult,
          pcrResult,
          ctValue,
        } = data;
        let dataToBeUpload = {
          name,
          requestLab,
          nationality,
          identityNumber,
          passportNumber,
          gender,
          phoneNumber,
          email,
          address,
          antigenResult,
          pcrResult,
          birthDate,
          samplingDate,
          sampleSender,
          receiveSampleDate,
          resultDate,
          ctValue,
          location: "Laboratorium EBDC",
          userIdCreate: this.currentUser.id,
          userNameCreate: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
          userIdUpdate: this.currentUser.id,
          userNameUpdate: `${this.currentUser.firstName} ${this.currentUser.lastName}`,
        };
        await this.$store.dispatch("addPasien", dataToBeUpload);
        processedData++;
        this.progressData = (processedData / totalData) * 100;
      }

      this.isLoading = false;
      this.statusText = `${totalData} data selesai di impor, Silakan tutup form ini.`;
      this.doneImporting = true;
    },
  },
};
</script>

<style scoped></style>
